.page-privacy {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.page-privacy .container {
  display: block;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.page-privacy .logo {
  display: block;
  margin: 0 auto 20px;
}

.page-privacy h1 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

.page-privacy p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Dark mode styles */
.page-privacy.dark {
  background-color: #333;
}

.page-privacy.dark .container {
  border: 1px solid #292929;
  background-color: #444;
  color: #fff;
}

.page-privacy.dark input {
  background-color: #555;
  color: #fff;
  border: 1px solid #666;
}

.page-privacy.dark .logo {
  filter: brightness(1.5) saturate(11.5);
}
