/* App.css */

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.page-link .container {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}


.page-link form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* or whatever width you prefer */
}

.page-link input {
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  outline: none;
}

.page-link input:active,
.page-link input:focus
{
  border-color: #8d8d8d;
}

.input-group {
  margin-bottom: 15px;
  width: 100%;
}

.page-link button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.page-link .logo {
  width: 200px;
  margin-bottom: 20px;
}

.page-link button:hover {
  background-color: #555;
}

.page-link .error {
  color: red;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid red;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

.page-link .input-group {
  display: flex;
  position: relative;
}

.page-link .form-control {
  flex: 1;
  border-right: none !important; /* Remove border between input and button */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-link .password-group {
  margin-bottom: 1em; /* Adjust as needed */
}

.page-link .password-toggle {
  position: absolute;
  right: 0;
  padding: 10px; /* Adjust the padding to match your input's height */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.page-link .toggle-password-visibility {
  padding: 0.375rem 0.75rem;
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: none;
  color: #fff;
  background-color: #333;
}

.page-link .toggle-password-visibility:hover,
.page-link .toggle-password-visibility:focus
.page-link {
  color: #fff;
  background-color: #444;
  border-color: #ced4da;
}


/* Dark mode styles */
.page-link.dark {
  background-color: #333;
}

.page-link.dark .container {
  border: 1px solid #292929;
  background-color: #444;
  color: #fff;
}

.page-link.dark .horizontal-border {
  background-color: #555;
}

.page-link.dark input {
  background-color: #555;
  color: #fff;
  border: 1px solid #666;
}

.page-link.dark input::placeholder {
  color: #ccc;
}

.page-link.dark input:focus {
  outline: none;
  border-color: darkgray;
}

.page-link.dark .error {
  color: #ff6b6b;  /* Lighter shade of red for better visibility */
  background-color: #552222;  /* Dark red background for contrast */
}

.page-link.dark .logo {
  filter: brightness(1.5) saturate(11.5);
}

.page-link.dark .toggle-password-visibility {
  padding: 0.375rem 0.75rem;
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: none;
  color: #495057;
  background-color: #fff;
}

.page-link.dark .toggle-password-visibility:hover,
.page-link.dark .toggle-password-visibility:focus
{
  color: #495057;
  background-color: #e9ecef;
  border-color: #ced4da;
}

