.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Use the full height of the viewport */
  }
  
  .home-container img {
    max-width: 100%;
    height: auto;
  }